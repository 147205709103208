$global-padding: 4rem;
$global-padding-xlarge: 12rem;
$primary: #0f3f78;
$secondary: rgb(161, 28, 55);


@mixin dark_background {
    background-color: $primary;
    color: white;
}

@mixin light_background {
    background-color: white;
    color: #343434;;
}

$breakpoints: (
    'large': 87.75rem,
    'xlarge': 104.75rem,
    'xxlarge': 143.75rem,
);

@mixin breakpoint($breakpoint) {
    $bp: map.get($breakpoints, #{ $breakpoint });
    @media screen and (min-width: $bp) {
        @content;
    }
}
