
  /* barlow-300 - latin */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/barlow-v5-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/barlow-v5-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/barlow-v5-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/barlow-v5-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/barlow-v5-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/barlow-v5-latin-300.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  /* barlow-600 - latin */
  @font-face {
    font-family: 'Barlow';
    font-style: bold;
    font-weight: 600;
    src: url('../fonts/barlow-v5-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/barlow-v5-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/barlow-v5-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/barlow-v5-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/barlow-v5-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/barlow-v5-latin-600.svg#Barlow') format('svg'); /* Legacy iOS */
  }


body {
    font-family: 'Barlow', sans-serif;
}


.name {
    opacity: 0;
    animation: come-in 850ms forwards 850ms;
    font-size: 1.1rem;
    margin-bottom: .25rem;
    margin-top: 0;
    font-weight: normal;
    letter-spacing: -.4px;
    text-align: center;
}

.job {
    animation-name: come-in;
    animation-duration: 850ms;
    animation-timing-function: ease-in-out;

    text-align: center;
    letter-spacing: -.2px;
    margin: 0;
    color: $secondary;
    font-size: 1.1rem;
    transition: font-size 450ms;

    @include breakpoint(xlarge) {
        // font-size: 3.25rem;
    }

    line-height: 1.2;
}

.description {
    margin-left: auto;
    margin-right: auto;
    max-width: 55ch;
    align-self: center;
    font-size: 1.15rem;
}