/* barlow-300 - latin */
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 300;
  src: url("barlow-v5-latin-300.7369892c.eot");
  /* IE9 Compat Modes */
  src: local(""), url("barlow-v5-latin-300.7369892c.eot#iefix") format("embedded-opentype"), url("barlow-v5-latin-300.8e8c4143.woff2") format("woff2"), url("barlow-v5-latin-300.d99b1312.woff") format("woff"), url("barlow-v5-latin-300.eade2973.ttf") format("truetype"), url("barlow-v5-latin-300.1f98fe10.svg#Barlow") format("svg");
  /* Legacy iOS */
}
/* barlow-600 - latin */
@font-face {
  font-family: "Barlow";
  font-style: bold;
  font-weight: 600;
  src: url("barlow-v5-latin-600.03aec0be.eot");
  /* IE9 Compat Modes */
  src: local(""), url("barlow-v5-latin-600.03aec0be.eot#iefix") format("embedded-opentype"), url("barlow-v5-latin-600.c7c54aa7.woff2") format("woff2"), url("barlow-v5-latin-600.6b4bdf91.woff") format("woff"), url("barlow-v5-latin-600.fd1efbe8.ttf") format("truetype"), url("barlow-v5-latin-600.17158ceb.svg#Barlow") format("svg");
  /* Legacy iOS */
}
body {
  font-family: "Barlow", sans-serif;
}
.name {
  opacity: 0;
  animation: come-in 850ms forwards 850ms;
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
  margin-top: 0;
  font-weight: normal;
  letter-spacing: -0.4px;
  text-align: center;
}
.job {
  animation-name: come-in;
  animation-duration: 850ms;
  animation-timing-function: ease-in-out;
  text-align: center;
  letter-spacing: -0.2px;
  margin: 0;
  color: #a11c37;
  font-size: 1.1rem;
  transition: font-size 450ms;
  line-height: 1.2;
}
.description {
  margin-left: auto;
  margin-right: auto;
  max-width: 55ch;
  align-self: center;
  font-size: 1.15rem;
}
html,
body {
  height: 100%;
  margin: 0;
}
a {
  color: white;
}
body {
  background-color: white;
  color: #343434;
  --webkit-font-smoothing: auto;
  --moz-osx-font-smoothing: auto;
  display: grid;
  grid-template-rows: 1fr auto;
}
body header,
body main,
body footer {
  padding-left: 4rem;
  padding-right: 4rem;
}
@media screen and (min-width: 104.75rem) {
  body header,
body main,
body footer {
    padding-left: 12rem;
    padding-right: 12rem;
  }
}
body header {
  padding-top: 4rem;
  display: flex;
}
body main {
  align-self: center;
  justify-content: center;
}
body footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 0.75rem;
}
@keyframes come-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.button {
  width: 3.25rem;
  position: relative;
}
.button:not(:last-child) {
  margin-right: 1rem;
}
.button::before {
  content: "1";
  font-family: Helvetica, Arial, sans-serif;
  width: 1.75rem;
  height: 1.75rem;
  right: -0.5rem;
  top: -0.5rem;
  position: absolute;
  background: red;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 2px #000;
  transform: scale(0);
  transition: transform 250ms;
}
.button:hover::before, .button:focus::before {
  transition: transform 250ms;
  transform: scale(1);
}
@media (orientation: landscape) {
  aside {
    columns: 2;
  }
}
@media screen and (orientation: landscape) and (min-width: 87.75rem) {
  aside {
    columns: unset;
  }
}
/*# sourceMappingURL=index.5a22aaf8.css.map */
