@use "sass:map";
@import "./variables";
@import "./typography";

html,
body {
  height: 100%;
  margin: 0;
}

a {
  color: white;
}

body {
  @include light_background;

  --webkit-font-smoothing: auto;
  --moz-osx-font-smoothing: auto;
  display: grid;
  grid-template-rows: 1fr auto;

  header,
  main,
  footer {
    padding-left: $global-padding;
    padding-right: $global-padding;

    @include breakpoint(xlarge) {
      padding-left: $global-padding-xlarge;
      padding-right: $global-padding-xlarge;
    }
  }

  header {
    padding-top: $global-padding;
    display: flex;
  }

  main {
    align-self: center;
    justify-content: center;
  }

  footer {
    display: flex;
    justify-content: center;
    // flex-direction: column;
    align-items: flex-start;
    // padding-top: .5rem;
    padding-bottom: .75rem;

    @include breakpoint(xlarge) {
      // padding-bottom: $global-padding-xlarge;
    }
  }
}

@keyframes come-in {
  from {
    // transform: translateY(50vw);
    opacity: 0;
  }
  to {
    // transform: translateY(0);
    opacity: 1;
  }
}

.button {
  width: 3.25rem;
  position: relative;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &::before {
    content: "1";
    font-family: Helvetica, Arial, sans-serif;
    width: 1.75rem;
    height: 1.75rem;
    right: -.5rem;
    top: -.5rem;
    position: absolute;
    background: red;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 2px #000;
    transform: scale(0);
    transition: transform 250ms;
  }

  &:hover,
  &:focus {
      &::before {
        transition: transform 250ms;
        transform: scale(1);
      }
  }
}

@media (orientation: landscape) {
  aside {
    columns: 2;
  }

  @include breakpoint(large) {
    aside {
        columns: unset;
    }
  }
}